import React, { useCallback, useContext } from 'react'
import { groupBy } from 'lodash'
import classnames from 'classnames'
import SvgIcon from './SvgIcon'
import checkIcon from '../images/icons/check.svg'
import reactStringReplace from 'react-string-replace'
import '../styles/memberships.css'
import { BecomeMemberPopupContext } from '../components/PopupBecomeMember'
import { BecomePatronPopupContext } from '../components/PopupBecomePatron'

function renderCheckIcon(content) {
  return reactStringReplace(content, /^\s*yes\s*$/i, (match, idx) => (
    <SvgIcon key={`replaced-${idx}`} size={16} iconId={checkIcon.id} />
  ))
}

function GroupTable({ rows, className, actions, ...props }) {
  const colKeys = Object.keys(rows[0].plans[0])

  return (
    <table
      {...props}
      className={classnames('memberships-rows-group', className)}
    >
      <thead className="memberships-rows-group--thead">
        <tr>
          {colKeys.map((col, idx) => (
            <th
              className={classnames(
                'memberships-table-head--th',
                `membership-${col}`
              )}
              key={`${idx}-${col}`}
            >
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="memberships-rows-group--tbody">
        {rows.map(({ plans, title }, idx) => (
          <tr
            key={`${idx}-${title}`}
            className="memberships-rows-group--tbody__row"
          >
            <th scope="row" className="memberships-group-row--th">
              {title}
            </th>
            {colKeys.map((col, idx) => (
              <td
                key={`${title}-${idx}`}
                className={classnames(
                  'flex-col items-center',
                  'memberships-group-row--td',
                  `membership-${col}`
                )}
              >
                {plans.map((plan, idx) => (
                  <div
                    key={`${col}-${idx}`}
                    className="flex justify-center items-center"
                    style={{ flexBasis: `${100 / plans.length}%` }}
                  >
                    {renderCheckIcon(plan[col])}
                  </div>
                ))}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot className="memberships-rows-group--tfoot">
        <tr className="memberships-rows-group--tbody__row">
          {Object.keys(actions).map((col, idx) => (
            <td
              key={`${idx}-${col}`}
              className={classnames('memberships-group-row--td')}
              dangerouslySetInnerHTML={{ __html: actions[col].html }}
            />
          ))}
        </tr>
      </tfoot>
    </table>
  )
}

function MembershipTable({ rows, actions, ...props }) {
  const groups = groupBy(rows, 'category')

  const { togglePopup: memberPopupToggle } = useContext(
    BecomeMemberPopupContext
  )
  const { togglePopup: patronPopupToggle } = useContext(
    BecomePatronPopupContext
  )

  const handleClick = useCallback(e => {
    const clicked = e.target

    if (!clicked.dataset || !clicked.dataset.form) {
      return
    }

    e.preventDefault()

    switch (clicked.dataset.form) {
      case 'becomeMember':
        memberPopupToggle()
        break
      case 'becomePatrol':
        patronPopupToggle()
        break
    }
  }, [])

  return groups ? (
    <div className="memberships-wrapper" {...props} onClick={handleClick}>
      <div className="memberships-table-head">
        <div className="memberships-table-head--row">
          {Object.keys(rows[0].plans[0]).map((col, idx) => (
            <div
              key={`${idx}-${col}`}
              className={classnames(
                'memberships-table-head--th',
                `membership-${col}`
              )}
            >
              {col}
            </div>
          ))}
        </div>
      </div>

      {Object.entries(groups).map(([groupName, rows], idx) => (
        <GroupTable
          key={`${idx}-${groupName}`}
          rows={rows}
          data-group-name={groupName}
          actions={actions}
        />
      ))}

      <div className="memberships-table-foot">
        <div className="memberships-table-foot--row">
          {Object.keys(actions).map((col, idx) => (
            <div
              key={`${idx}-${col}`}
              className={classnames('memberships-table-foot--th')}
              dangerouslySetInnerHTML={{ __html: actions[col].html }}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null
}

export default MembershipTable
