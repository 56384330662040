import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import MembershipTable from '../components/MembershipTable'
import Articles from '../components/sections/Articles'
import { renderAstWithTextPose } from '../components/AstRenderer'
import HalfColumns from '../components/sections/HalfColumns'
import GatsbyImage from 'gatsby-image'
import Button from '../components/Button'
import NominatePopup, {
  NominatePopupContext,
} from '../components/PopupNominate'
import ApplyPopup, { ApplyPopupContext } from '../components/PopupApply'
import BecomeMemberPopup from '../components/PopupBecomeMember'
import BecomePatronPopup from '../components/PopupBecomePatron'

function ClubPage({
  nominateGoogleFormCode,
  applyGoogleFormCode,
  becomeMemberGoogleFormCode,
  becomePatronGoogleFormCode,
  membershipRows,
  membershipActions,
  introArticles,
  activitiesSection,
}) {
  const {
    activities,
    activitiesTitle,
    activitiesSharp,
    activitiesActions,
  } = activitiesSection
  // console.log(`introArticles: `, introArticles)

  const introArticlesProps = {}
  if (introArticles[0]) {
    introArticlesProps.left = introArticles[0].html
  }

  if (introArticles[1]) {
    introArticlesProps.right = introArticles[1].html
  }

  const activitiesColsProps = {}
  if (activities[0]) {
    activitiesColsProps.left = activities[0].html
  }

  if (activities[1]) {
    activitiesColsProps.right = activities[1].html
  }

  const { togglePopup: nominatePopupToggle } = useContext(NominatePopupContext)
  const { togglePopup: applyPopupToggle } = useContext(ApplyPopupContext)

  return (
    <div className="container my-10">
      <HalfColumns {...introArticlesProps} />

      <MembershipTable rows={membershipRows} actions={membershipActions} />

      <section>
        {activitiesSharp ? (
          <GatsbyImage className="mb-8" fluid={activitiesSharp.fluid} />
        ) : null}
        {activitiesTitle ? (
          <h2 className="text-center mb-8">{activitiesTitle}</h2>
        ) : null}

        <HalfColumns {...activitiesColsProps} />

        <div className="btn-group text-center">
          {activitiesActions.map(({ buttonText, buttonLink }, idx) => {
            const btnProps = {}
            let onClick = null

            switch (buttonLink) {
              case '#nominate':
                btnProps.onClick = nominatePopupToggle
                break
              case '#apply':
                btnProps.onClick = applyPopupToggle
                break
              default:
                btnProps.link = buttonLink
            }

            return (
              <Button
                key={`${idx}-${buttonLink}`}
                size="lg"
                variant="turquoise"
                className="text-base leading-normal uppercase"
                // icon="crown"
                link={buttonLink}
                onClick={onClick}
                {...btnProps}
              >
                {buttonText}
              </Button>
            )
          })}
        </div>
      </section>
    </div>
  )
}

function ClubPageQueryParser({ data, pageContext, ...props }) {
  const {
    membershipTable: { rows: membershipRows = [], actions: membershipActions },
    page: {
      intro: { items: intro = [] },
      activities: {
        items: activitiesItems = [],
        title: activitiesTitle = '',
        image: { sharp: activitiesSharp },
        actions: activitiesActions,
      },
    },
    generalSettings,
  } = data

  const introArticles = intro.map(({ excerptMd: { remark, id } }) => ({
    id,
    html: renderAstWithTextPose(remark.htmlAst),
  }))

  const activities = activitiesItems.map(({ excerptMd: { remark, id } }) => ({
    id,
    html: renderAstWithTextPose(remark.htmlAst),
  }))

  const nominateGoogleFormCode =
    (generalSettings.yaml && generalSettings.yaml.googleformNominateCode) || ''

  const applyGoogleFormCode =
    (generalSettings.yaml && generalSettings.yaml.googleformApplyCode) || ''

  const becomeMemberGoogleFormCode =
    (generalSettings.yaml && generalSettings.yaml.googleformBecomeMemberCode) ||
    ''

  const becomePatronGoogleFormCode =
    (generalSettings.yaml && generalSettings.yaml.googleformBecomePatronCode) ||
    ''

  return (
    <BecomeMemberPopup popupContent={becomeMemberGoogleFormCode}>
      <BecomePatronPopup popupContent={becomePatronGoogleFormCode}>
        <ApplyPopup popupContent={applyGoogleFormCode}>
          <NominatePopup popupContent={nominateGoogleFormCode}>
            <ClubPage
              {...{
                membershipRows,
                membershipActions,
                introArticles,
                activitiesSection: {
                  activities,
                  activitiesTitle,
                  activitiesSharp,
                  activitiesActions,
                },
              }}
              {...props}
            />
          </NominatePopup>
        </ApplyPopup>
      </BecomePatronPopup>
    </BecomeMemberPopup>
  )
}

export default ClubPageQueryParser

export const clubPageQuery = graphql`
  query clubQuery($lang: String) {
    ...SettingsNominateGoogleFormCodeFragment
    ...SettingsApplyGoogleFormCodeFragment
    ...SettingsBecomeMemberGoogleFormCodeFragment
    ...SettingsBecomePatronGoogleFormCodeFragment

    membershipTable: membershipYaml(lang: { eq: $lang }) {
      rows {
        category
        title
        plans {
          Builder
          Creator
          Fabricator
          Patron
        }
      }
      actions {
        Builder {
          html
        }
        Creator {
          html
        }
        Fabricator {
          html
        }
        Patron {
          html
        }
      }
    }

    page: clubYaml(fields: { lang: { eq: $lang } }) {
      intro {
        items {
          excerptMd {
            id
            remark: childMarkdownRemark {
              htmlAst
            }
          }
        }
      }

      activities {
        title
        image {
          sharp: childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        items {
          excerptMd {
            id
            remark: childMarkdownRemark {
              htmlAst
            }
          }
        }

        actions {
          buttonText
          buttonLink
        }
      }

      ...PageSeoFragment
    }
  }
`
