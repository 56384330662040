import { graphql } from 'gatsby'
// import React from 'react'
import { createPopup } from './Popup'

const { Popup, Context } = createPopup()

export default Popup
export { Context as BecomePatronPopupContext }

export const generalSettingsBecomePatronFormFragment = graphql`
  fragment SettingsBecomePatronGoogleFormCodeFragment on Query {
    generalSettings: file(
      relativeDirectory: { eq: "settings" }
      name: { eq: "general" }
    ) {
      yaml: childSettingsYaml {
        googleformBecomePatronCode
      }
    }
  }
`
