import { graphql } from 'gatsby'
// import React from 'react'
import { createPopup } from './Popup'

const { Popup, Context } = createPopup()

export default Popup
export { Context as BecomeMemberPopupContext }

export const generalSettingsBecomeMemberFormFragment = graphql`
  fragment SettingsBecomeMemberGoogleFormCodeFragment on Query {
    generalSettings: file(
      relativeDirectory: { eq: "settings" }
      name: { eq: "general" }
    ) {
      yaml: childSettingsYaml {
        googleformBecomeMemberCode
      }
    }
  }
`
